.App {
  padding: 20px;
  background-color: #121212;
} 


.box {
  background-color: rgba(255, 255, 255, 0.12);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}